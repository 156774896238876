import React from 'react';
import * as styles from './services.module.css';

import Container from '../../components/Container';
import Layout from '../../components/Layout';
import Accordion from '../../components/Accordion';
import BeforeAfter from '../../components/BeforeAfter';
import Image from '../../components/Image';
import { Link } from 'gatsby';

const VinylRelinePage = () => {
  return (
    <Layout disablePaddingBottom>
        <div className={styles.root}>
        <Container>
            <h1 data-fade-in-section className={styles.header}>Vinyl Liner Replacement</h1>
            <div data-fade-in-section className={styles.descriptionSection}>
                <div>
                    <p>
                        Vinyl-lined swimming pools typically require replacement of the liner every 10-15 years on average.
                        With a wide selection of designs, replacing a liner gives homeowners the opportunity to give their pool a
                        new personality and a fresh look to the backyard.
                    </p>
                    <p>
                        A Pristine Pool &amp; Spa representative will provide an inspection of the pool liner as well as the structure
                        and equipment of your pool and present all available options to you. We will help you choose a liner
                        pattern and discuss any changes you may be interested in such as new lighting, skimmer changeout,
                        step additions/modifications and equipment upgrades.
                    </p>
                </div>
                <div>
                    <p>
                        We primarily use liners from Latham, the largest designer and manufacturer of in-ground residential
                        swimming pools in North America. Latham is one of the only pool manufacturers to receive ICC-ES
                        Certification of its products. All Latham liners come with a 20-year warranty (3-year full, 17-year pro-rated). Latham is known throughout the industry as providing the highest-quality products for vinyl
                        pools and their products are manufactured in the United States.
                    </p>
                    <p>See <Link to="https://www.lathampool.com/vinyl-liner-patterns/">
                        Latham's wide selection of liner patterns</Link>.
                    </p>
                </div>
            </div>
        </Container>
        <Container>
            <div data-fade-in-section>
                <div className={styles.accordionSection}>
                    <Accordion title="How long does a liner replacement take?">
                        <p>
                            A standard liner replacement is typically a 2-4 day process which includes draining the pool, removal of
                            the old liner, replacing track if necessary, pool base repair/touch up, setting the new liner, filling the
                            pool, and replacing inlet faceplates and gaskets.
                        </p>
                    </Accordion>
                    <Accordion title="How do I know when it is time to replace my vinyl liner?">
                        <p>
                            The average lifespan of a quality vinyl liner is 10-15 years. After this time, it is possible that leaks may
                            start to occur caused by normal wear and tear.
                            Even if the liner is not leaking, after a decade or so, it is likely that the liner will have faded from
                            chemical and sun exposure. A new liner will give you an upgraded, fresh look to your backyard.
                            If your liner has damage from a chemical imbalance and/or over-chlorination, you may see numerous
                            small wrinkles form on the base of the pool (see below). If this occurs, there is a higher chance of the
                            liner being punctured and it may be difficult or impossible to properly repair. Replacing the liner is likely
                            your best option.
                        </p>
                        <img width="400px" src="/liner_chemical_damage.jpeg"></img>
                    </Accordion>
                    <Accordion title="How can I extend the lifespan of my liner?">
                        <p className={styles.accordionContent}>
                            <ul>
                                <li>
                                    <strong>Keep the water balanced</strong>{' '}
                                    Ensuring that the chlorine and pH levels of your water are balanced within the recommended
                                    ranges slows down the natural deterioration of the surface of the pool liner. Proper water
                                    balance keeps the liner soft and supple so that it won’t become as brittle as it ages. A
                                    consistently high or low pH and/or a high chlorine level is detrimental to a vinyl pool. Total
                                    alkalinity and calcium hardness are also chemical balance factors.
                                </li>
                                <li>
                                    <strong>
                                        Use liquid chlorine (or other liquid sanitizer)
                                    </strong>{' '}
                                    Chlorine tablets and granular chlorine that is not dissolved can bleach vinyl liners, cause
                                    chemical damage and reduce the longevity of the liner. For homeowners with a vinyl-lined pool,
                                    it is highly recommended not to use chlorine tablets in floaters or in the skimmer. Using the
                                    proper amount of liquid chlorine and/or dissolved granular chlorine is the best way to sanitize
                                    vinyl-lined pools.
                                </li>
                                <li>
                                    <strong>
                                        Avoid punctures
                                    </strong>{' '}
                                    Don't allow sharp objects in the pool. Use pool cleaning tools that are specifically designed for
                                    use in vinyl-lined pools (i.e. vinyl rather than metal brushes). Use a pool cleaner that is safe for
                                    vinyl liner pools. Especially if you have vinyl-covered steps, it is a good idea to keep dogs and
                                    other animals out of the pool.
                                </li>
                            </ul>
                        </p>
                    </Accordion>
                    <Accordion title="How do I know if my pool is leaking?">
                        <div className={`${styles.bucketTestContent} ${styles.accordionContent}`}>
                            <p>
                                This simple bucket test will help you to determine if you
                                need a leak detection service.
                                <br></br>
                                <br></br>
                                <ol>
                                    <li>
                                        Begin with a 5-gallon bucket and fill it with water 2&quot;-3&quot; from top. Place on
                                        the first or second step in the pool or spa. If necessary, anchor the bucket by
                                        placing a rock inside it.
                                    </li>
                                    <li>
                                        When the water in the bucket and pool settles, mark the level of the bucket&#39;s
                                        water inside the bucket (we recommend using a pencil or a permanent marker).
                                    </li>
                                    <li>
                                        Mark the pool water level on the outside of the bucket.
                                    </li>
                                    <li>
                                        After 2-3 days mark the new water levels &amp; compare the water loss in both
                                        pool and the bucket.
                                    </li>
                                </ol>
                                <br></br>
                                If the pool water loss is 0.5" or more than the bucket evaporation, it is likely that your pool is leaking. If not covered, some pools can lose up to ¼” per day from evaporation. 
                                <br></br>
                                <br></br>
                                Please <a href="/contact">contact us</a> for a referral to a leak detection and repair specialist.
                            </p>
                            <img width="200px" src="/bucket_test.jpeg"></img>
                        </div>
                    </Accordion>
                </div>
            </div>
        </Container>
        <Container>
            <section className={styles.imagesSection}>
                <Image hideLabel height={460} src="/reline_process_1.jpeg"></Image>
                <Image hideLabel height={460} src="/reline_process_2.jpeg"></Image>
                <Image hideLabel height={460} src="/reline_process_3.jpeg"></Image>
                <Image hideLabel height={460} src="/reline_process_4.jpeg"></Image>
            </section>
        </Container>
      </div>
    </Layout>
  );
};

export default VinylRelinePage;
