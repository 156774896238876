import React from 'react';
import * as styles from './Image.module.css';

const Image = (props) => {
  const {
    caption,
    captionTitle,
    src,
    height,
  } = props;

  const renderCaption = () => {
    if (caption || captionTitle) {
        return (
            <p className={styles.caption}>
                {captionTitle && <b>{captionTitle}</b>}
                {captionTitle && <br></br>}
                {caption}
            </p>
        );
    }
    return '';
  };

  return (
    <div className={styles.root}>
        <img className={styles.image} height={height} src={src}></img>
        {renderCaption()}
    </div>
  );
};

export default Image;
